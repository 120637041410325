import styles from './SuggestionWrapper.module.css';
import {
    getImplantPreview,
    getSearchSuggestions,
} from '../../../services/apiService';
import { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFileLines,
    faGear,
    faList,
    faNoteSticky,
    faXRay,
} from '@fortawesome/free-solid-svg-icons';
import SuggestionPreview from './preview/SuggestionPreview';
import Button, { ButtonType } from '../../general-components/Button';
import Status from '../../general-components/Status';
import GppGoodIcon from '@mui/icons-material/GppGood';
import AuthContext from '../../../store/auth-context';
import { Tooltip } from '@mui/material';

const SuggestionWrapper: React.FC<{ term: string }> = ({ term }) => {
    const [results, setResults] = useState<any[]>([]);
    const [previewType, setPreviewType] = useState<{
        type: '' | 'implant' | 'type' | 'manufacturer' | 'protocol';
        id: string;
    }>({ type: '', id: '' });
    const [hoveredId, setHoveredId] = useState(0);

    const ctx = useContext(AuthContext);

    const search = async (term: string) => {
        const res = await getSearchSuggestions(term);
        setResults(res);
    };

    const incrementHover = () => {
        console.log(hoveredId);
        if (hoveredId < 9 && hoveredId < results.length) {
            setHoveredId(hoveredId + 1);
        }
    };

    const decrementHover = () => {
        console.log(hoveredId);
        if (hoveredId > 0) {
            setHoveredId(hoveredId - 1);
        }
    };

    useEffect(() => {
        const handleKeyDown = (event: any) => {
            if (event.key === 'ArrowDown') {
                event.preventDefault();
                incrementHover();
            }
            if (event.key === 'ArrowUp') {
                event.preventDefault();
                decrementHover();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        if (term.length >= 3) {
            search(term);
        } else {
            setResults([]);
            setPreviewType({ type: '', id: '' });
        }
    }, [term]);

    const updatePreviewType = async () => {
        if (results.length > 0) {
            if (results[hoveredId].implant_id) {
                setPreviewType({
                    type: 'implant',
                    id: results[hoveredId].implant_id,
                });
            }
            if (results[hoveredId].manufacturer_id) {
                setPreviewType({
                    type: 'manufacturer',
                    id: results[hoveredId].manufacturer_id,
                });
            }
            if (results[hoveredId].internalProtocol_id) {
                setPreviewType({
                    type: 'protocol',
                    id: results[hoveredId].internalProtocol_id,
                });
            }
            if (results[hoveredId].type_name) {
                setPreviewType({
                    type: 'type',
                    id: results[hoveredId].type_name,
                });
            }
        }
    };

    useEffect(() => {
        updatePreviewType();
    }, [results, hoveredId]);

    if (results.length > 0) {
        return (
            <div className={styles.wrapper}>
                <div className={styles.list}>
                    {results.map((r, index) => (
                        <div
                            onMouseEnter={() => setHoveredId(index)}
                            // onMouseLeave={handleMouseLeave(index)}
                            key={index}
                            className={`${styles.result} ${
                                hoveredId === index ? styles.hover : ''
                            }`}
                        >
                            {r.implant_model && (
                                <a
                                    href={'/implant/' + r.implant_id}
                                    className={styles.implantLink}
                                >
                                    <div className={styles.icon}>
                                        <Status
                                            status={r.implant_status}
                                            small
                                        />
                                    </div>
                                    <p>{r.implant_model}</p>
                                    <div className={styles.approved}>
                                        {r.implant_approvals?.includes(
                                            ctx.getContext().department._id
                                        ) && (
                                            <Tooltip
                                                title={
                                                    'This implant is approved at ' +
                                                    ctx.getContext().department
                                                        .name
                                                }
                                            >
                                                <GppGoodIcon />
                                            </Tooltip>
                                        )}
                                    </div>
                                </a>
                            )}
                            {r.type_name && (
                                <a
                                    href={'/all?type=' + r.type_name}
                                    className={styles.implantLink}
                                >
                                    <p>
                                        <FontAwesomeIcon icon={faList} />
                                        {r.type_name}
                                    </p>
                                </a>
                            )}
                            {r.manufacturer_name && (
                                <a
                                    href={
                                        '/all?manufacturer=' + r.manufacturer_id
                                    }
                                    className={styles.implantLink}
                                >
                                    <p>
                                        <FontAwesomeIcon icon={faGear} />
                                        {r.manufacturer_name}
                                    </p>
                                </a>
                            )}
                            {r.internalProtocol_title && (
                                <a
                                    href={'/protocols/' + r.internalProtocol_id}
                                    className={styles.implantLink}
                                >
                                    <p>
                                        <FontAwesomeIcon icon={faFileLines} />
                                        {r.internalProtocol_title}
                                    </p>
                                </a>
                            )}
                        </div>
                    ))}
                </div>
                <div className={styles.seeResults}>
                    <Button
                        type={ButtonType.secondary}
                        onClick={() => {
                            window.location.href = '/search/query?term=' + term;
                        }}
                    >
                        See all results
                    </Button>
                </div>
                <div className={styles.preview}>
                    <SuggestionPreview
                        type={previewType.type}
                        id={previewType.id}
                    />
                </div>
            </div>
        );
    }
    if (results.length === 0) {
        return (
            <div className={styles.noResult}>
                <h3>No results</h3>
            </div>
        );
    }
};

export default SuggestionWrapper;
