import React, { useEffect, useState } from 'react';
import Button, { ButtonType } from '../general-components/Button';
import styles from './MriCode.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';

interface SerialCode {
    code: string;
}

const MRICode: React.FC<SerialCode> = ({ code }) => {
    const [isCopied, setIsCopied] = useState(false);
    const [btnText, setBtnText] = useState(`MRI-code: ${code}`);

    const copyText = async (text: string) => {
        await navigator.clipboard.writeText(text);
        console.log('Code was copied to clipboard');
    };

    const handleChangeCopied = () => {
        setBtnText('Copied!');
        setIsCopied(true);
        copyText(code);
    };

    useEffect(() => {
        const copyTextEffect = setTimeout(() => {
            setIsCopied(false);
        }, 2000);
        return () => clearTimeout(copyTextEffect);
    }, [isCopied]);

    return (
        <Button onClick={handleChangeCopied} type={ButtonType.action}>
            <FontAwesomeIcon icon={faCopy} />
            <p>{btnText}</p>
        </Button>
    );
};

export default MRICode;
