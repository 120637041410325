import ConditionalsText from '../conditionals/ConditionalsText';
import CatalogNumbers from '../CatalogNumbers';
import ConditionalsTable from '../conditionals/ConditionalsTable';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import SETTING_KEYS from '../../../constants/setting-keys';
import { useContext, useState } from 'react';
import AuthContext from '../../../store/auth-context';
import RIGHTS_KEYS from '../../../constants/rights-keys';
import styles from './ImplantDetailsComponent.module.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const renderValue = (key: string, value: string | string[] | number) => {
    if (value === undefined || value === null || value === '') {
        return 'Not specified';
    } else if (key === SETTING_KEYS.distEndDate) {
        return (
            <ConditionalsText
                title={key}
                delimiter=":"
                text={new Date(value as string).toLocaleDateString()}
            />
        );
    } else if (key === SETTING_KEYS.maxStentLength) {
        <ConditionalsText
            title={key}
            delimiter=" |"
            text={(value as number).toString() + ' mm'}
        />;
    } else if (key === SETTING_KEYS.catalogNumbers) {
        return <CatalogNumbers data={value as any[]} />;
    } else if (Array.isArray(value)) {
        return (
            <ConditionalsText
                inline
                title={key}
                delimiter=":"
                text={value.join(', ') as string}
            />
        );
    } else if (value !== undefined && value !== '') {
        return (
            <ConditionalsText
                inline
                title={key}
                delimiter=":"
                text={value as string}
            />
        );
    }
    return 'Not specified';
};

const ImplantDetailsComponent: React.FC<{ info: any; hasToggle?: boolean }> = ({
    info,
    hasToggle = false,
}) => {
    const ctx = useContext(AuthContext);

    const [showDetails, setShowDetails] = useState(false);
    const toggleDetails = () => {
        setShowDetails(!showDetails);
    };

    let infoKeys = [
        SETTING_KEYS.category,
        SETTING_KEYS.distEndDate,
        SETTING_KEYS.bodyPlacement,
    ];

    if (ctx.orRights([RIGHTS_KEYS.advancedSearch])) {
        infoKeys.push(SETTING_KEYS.catalogNumbers);
    }

    if (ctx.orRights([RIGHTS_KEYS.detailedInfo])) {
        infoKeys = infoKeys.concat([
            SETTING_KEYS.maxStentLength,
            SETTING_KEYS.gmdnDefinition,
        ]);
    }

    return (
        <div className={styles.wrapper}>
            {hasToggle && (
                <div className={styles.toggle}>
                    <p className={styles.toggleText} onClick={toggleDetails}>
                        {showDetails ? 'Hide' : 'See'} device details{' '}
                        {showDetails ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </p>
                </div>
            )}
            {(!hasToggle || showDetails) && (
                <div className={styles.details}>
                    {infoKeys.map((key) => {
                        if (
                            info[key] === undefined ||
                            info[key] === null ||
                            info[key] === '' ||
                            (info[key]?.length === 1 &&
                                (info[key][0] === '' ||
                                    info[key][0].value === '')) ||
                            info[key]?.length === 0
                        ) {
                            return '';
                        }
                        return renderValue(key, info[key]);
                    })}
                </div>
            )}
        </div>
    );
};

export default ImplantDetailsComponent;
