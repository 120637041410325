import { useEffect, useState, useRef, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { getImplantsByTerm } from '../../services/apiService';
import Search from './Search';
import styles from './ResultsComponent.module.css';
import LoadingRing from '../ui-components/LoadingRing';
import Button, { ButtonType } from '../general-components/Button';
import ReportMissingImplant from './ReportMissingImplant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faFlag } from '@fortawesome/free-regular-svg-icons';
import SETTING_KEYS from '../../constants/setting-keys';
import SortIcon from '../ui-components/SortIcon';
import { mriCodeValidate } from '../../services/dataValidation';
import AuthContext from '../../store/auth-context';
import React from 'react';
import RIGHTS_KEYS from '../../constants/rights-keys';
import Implant from '../implant/Implant';
import OverlayWindow from '../ui-components/OverlayWindow';
import NewImplantBadge from '../implant/new-implant-badge/NewImplantBadge';
import Status from '../general-components/Status';
import ResultFilter from './ResultFilters';
import ActiveFilters from './ActiveFilters';
import { toTitleCase } from '../../utils/utils';
import ListToConditionalsPopup from '../custom-conditionals/view/ListToConditionalsPopup';

const ResultsComponent: React.FC<{
    term: string;
    defaultSemantic: boolean;
    isFullview?: boolean;
}> = ({ term, defaultSemantic, isFullview = true }) => {
    const [results, setResults] = useState<any[]>([]);
    const [resultsLoading, setResultsLoading] = useState(false);
    const [showReportModal, setShowReportModal] = useState(false);
    const [skipAmount, setSkipAmount] = useState(0);
    const [sortStatus, setSortStatus] = useState(['none', '']);
    const resultCompRef = useRef<HTMLDivElement>();
    const [path, setPath] = useState('');
    const [showImplantPage, setShowImplantPage] = useState(false);
    const [suggestedText, setSuggestedText] = useState('');

    const [currentTerm, setCurrentTerm] = useState(term);
    const [useSemantic, setUseSemantic] = useState(defaultSemantic);

    const userContext = useContext(AuthContext);
    const [editId, setEditId] = useState('');

    const [activeFilters, setActiveFilters] = useState<{
        [key: string]: string[];
    }>({ Types: [] });

    const sort = (event: React.MouseEvent) => {
        setSkipAmount(0);
        let parent = event.target as HTMLElement;
        while (parent.tagName !== 'TH') {
            parent = parent.parentElement as HTMLElement;
        }
        const v = parent.id;

        if (sortStatus[0] === v) {
            if (sortStatus[1] === '') {
                setSortStatus([v, 'asc']);
            } else if (sortStatus[1] === 'asc') {
                setSortStatus([v, 'desc']);
            } else if (sortStatus[1] === 'desc') {
                setSortStatus(['none', '']);
            }
        } else {
            setSortStatus([v, 'asc']);
        }
    };

    const search = async (
        sTerm: string,
        s: boolean,
        filters?: { [key: string]: string[] }
    ) => {
        setCurrentTerm(sTerm);
        setUseSemantic(s);
        let path = '/search/query?term=' + sTerm;
        if (s) {
            path += '&useSemantic=true';
        }
        // if (filters) {
        //     path += `&filters=${JSON.stringify(filters)}`;
        // }
        if (isFullview) {
            window.history.replaceState(null, 'NordRadiology', path);
        }

        setResultsLoading(true);
        const implants = await getImplants(sTerm, 0, s, filters);
        setSkipAmount(100);
        setResults(implants);
        setResultsLoading(false);
    };

    const searchOnScroll = async (sTerm: string, skip: number) => {
        const implants = await getImplants(sTerm, skip, useSemantic);
        setResults((prev) => prev.concat(implants));
    };

    const getImplants = async (
        sTerm: string,
        skip: number,
        semantic: boolean,
        filters?: { [key: string]: string[] }
    ) => {
        setSuggestedText(null);
        const result = await getImplantsByTerm(
            sTerm,
            skip,
            sortStatus[0],
            sortStatus[1],
            semantic,
            filters
        );

        setSuggestedText(result.newTerm);

        let arr = [];
        if (Array.isArray(result.implants)) {
            arr = result.implants;
        } else if (typeof result.implants === typeof '') {
            arr = new Array(result.implants);
        }
        setResultsLoading(false);
        return arr;
    };

    useEffect(() => {
        const pageComp = resultCompRef.current.parentElement;
        const handleScroll = () => {
            if (
                resultCompRef.current.getBoundingClientRect().height <
                    pageComp.scrollTop +
                        document.documentElement.clientHeight &&
                results.length % 100 === 0
            ) {
                searchOnScroll(currentTerm, skipAmount);
                setSkipAmount((prev) => prev + 100);
                pageComp.removeEventListener('scroll', handleScroll);
            }
        };
        pageComp.addEventListener('scroll', handleScroll);

        return () => {
            pageComp.removeEventListener('scroll', handleScroll);
        };
    }, [results]);

    useEffect(() => {
        setResultsLoading(false);
        if (sortStatus[0] !== 'none') {
            (document.getElementById(sortStatus[0]) as HTMLElement).style[
                'fontWeight'
            ] = 'bold';
        }
    }, [results]);

    const mriCodeRedirect = async (term: string) => {
        const validMriCode = await mriCodeValidate(term);
        if (
            validMriCode &&
            userContext.orRights([RIGHTS_KEYS.advancedSearch])
        ) {
            const pathToImplantId = '/implant/' + term;
            setPath(pathToImplantId);
            setShowImplantPage(true);
        } else {
            setShowImplantPage(false);
        }
    };

    useEffect(() => {
        const sTerm = currentTerm === '' || !isFullview ? term : currentTerm;

        mriCodeRedirect(sTerm);

        search(sTerm, useSemantic);
    }, [term, sortStatus]);

    return (
        <div className={styles.wrapper} ref={resultCompRef}>
            {showReportModal && (
                <ReportMissingImplant
                    searchTerm={currentTerm}
                    onClose={() => setShowReportModal(false)}
                />
            )}
            {isFullview && (
                <div className={styles.search}>
                    <Search
                        defaultSemantic={useSemantic}
                        defaultValue={term}
                        hasQuickResult={false}
                        onSearch={(term: string, semanticEnabled: boolean) =>
                            search(term, semanticEnabled)
                        }
                    />
                    {suggestedText && (
                        <p className={styles.suggestedText}>
                            Didn't find what you were looking for? Try searching
                            for{' '}
                            <span>
                                <a href={'/search/query?term=' + suggestedText}>
                                    {suggestedText}
                                </a>
                            </span>{' '}
                            instead.
                        </p>
                    )}
                </div>
            )}

            {!resultsLoading && results.length === 0 && (
                <div className={styles.loading}>
                    <div className={styles.noresult}>
                        <div className={styles.loupe}>
                            <FontAwesomeIcon icon={faCircleXmark} />
                        </div>
                        <h3>No Results found</h3>
                    </div>

                    <Button
                        onClick={() => setShowReportModal(true)}
                        type={ButtonType.secondary}
                    >
                        <FontAwesomeIcon icon={faFlag} />
                        Report missing implant
                    </Button>
                </div>
            )}
            {!resultsLoading && showImplantPage && (
                <Navigate to={path} replace={true} />
            )}
            <div className={styles.section}>
                {resultsLoading && (
                    <div className={styles.loading}>
                        <LoadingRing size="large" />
                    </div>
                )}
                {!resultsLoading && results.length > 0 && (
                    <div className={styles.results}>
                        <h2>Search results</h2>
                        {/* {results.length < 100 && (
                            <ListToConditionalsPopup implants={results} />
                        )} */}
                        <ActiveFilters filters={activeFilters} />
                        <table className={styles.list}>
                            <thead>
                                <tr>
                                    <th
                                        id={SETTING_KEYS.model}
                                        onClick={(e) => sort(e)}
                                    >
                                        Name{' '}
                                        <SortIcon
                                            sortStatus={sortStatus}
                                            headerId={SETTING_KEYS.model}
                                        />
                                    </th>
                                    <th
                                        id={SETTING_KEYS.manufacturer}
                                        onClick={(e) => sort(e)}
                                    >
                                        Brand{' '}
                                        <SortIcon
                                            sortStatus={sortStatus}
                                            headerId={SETTING_KEYS.manufacturer}
                                        />
                                    </th>
                                    <th
                                        id={SETTING_KEYS.type}
                                        onClick={(e) => sort(e)}
                                    >
                                        Type{' '}
                                        <SortIcon
                                            sortStatus={sortStatus}
                                            headerId={SETTING_KEYS.type}
                                        />
                                    </th>
                                    <th id={SETTING_KEYS.type}>MRI Status </th>
                                    <th id="Comments">Comments </th>
                                    <th id="Verified">Verified </th>
                                </tr>
                            </thead>
                            <tbody>
                                {results.map((imp) => (
                                    <React.Fragment
                                        key={imp.info._id + '_button'}
                                    >
                                        {userContext.orRights([
                                            RIGHTS_KEYS.manageAllImplants,
                                        ]) && (
                                            <tr
                                                className={
                                                    styles['edit-button']
                                                }
                                            >
                                                <td>
                                                    <Button
                                                        key={
                                                            imp.info._id +
                                                            '_button'
                                                        }
                                                        onClick={() => {
                                                            setEditId(
                                                                imp.info._id
                                                            );
                                                        }}
                                                        type={
                                                            ButtonType.tietary
                                                        }
                                                        small={true}
                                                    >
                                                        Edit
                                                    </Button>
                                                </td>
                                            </tr>
                                        )}
                                        <tr
                                            onClick={() =>
                                                (window.location.href =
                                                    '/implant/' + imp.info._id)
                                            }
                                            key={imp.info._id}
                                        >
                                            <td>
                                                <a
                                                    href={
                                                        '/implant/' +
                                                        imp.info._id
                                                    }
                                                >
                                                    {
                                                        imp.info[
                                                            SETTING_KEYS.model
                                                        ]
                                                    }
                                                    <NewImplantBadge
                                                        createdAt={
                                                            imp.info[
                                                                SETTING_KEYS
                                                                    .createdAt
                                                            ]
                                                        }
                                                    />
                                                </a>
                                            </td>
                                            <td>
                                                {toTitleCase(
                                                    imp[
                                                        SETTING_KEYS
                                                            .manufacturer
                                                    ]
                                                )}
                                            </td>
                                            <td>
                                                {imp.info[SETTING_KEYS.type]}
                                            </td>
                                            <td>
                                                <Status
                                                    status={
                                                        imp.info[
                                                            SETTING_KEYS
                                                                .mriStatus
                                                        ]
                                                    }
                                                />
                                            </td>
                                            <td>{imp.info.commentCount}</td>
                                            {!imp.info.verifiedByDept?.includes(
                                                userContext.getContext()
                                                    .department._id
                                            ) && (
                                                <>
                                                    {!imp.info[
                                                        SETTING_KEYS
                                                            .verifiedByNI
                                                    ] &&
                                                        !imp.info[
                                                            SETTING_KEYS
                                                                .verifiedByManufacturer
                                                        ] && <td>-</td>}
                                                    {imp.info[
                                                        SETTING_KEYS
                                                            .verifiedByManufacturer
                                                    ] && (
                                                        <td
                                                            style={{
                                                                color: '#F0B074',
                                                            }}
                                                        >
                                                            Manufacturer
                                                        </td>
                                                    )}
                                                    {imp.info[
                                                        SETTING_KEYS
                                                            .verifiedByNI
                                                    ] &&
                                                        !imp.info[
                                                            SETTING_KEYS
                                                                .verifiedByManufacturer
                                                        ] && (
                                                            <td
                                                            // style={{
                                                            //     color: '#4BC17E',
                                                            // }}
                                                            >
                                                                -
                                                            </td>
                                                        )}
                                                </>
                                            )}
                                            {imp.info.verifiedByDept?.includes(
                                                userContext.getContext()
                                                    .department._id
                                            ) && (
                                                <td>
                                                    {
                                                        userContext.getContext()
                                                            .department.name
                                                    }
                                                </td>
                                            )}
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                        {editId.length > 0 &&
                            userContext.orRights([
                                RIGHTS_KEYS.manageAllImplants,
                            ]) && (
                                <OverlayWindow
                                    close={() => {
                                        setEditId('');
                                    }}
                                >
                                    <Implant isEdit={true} id={editId} />
                                </OverlayWindow>
                            )}
                    </div>
                )}
                {results.length > 0 && (
                    <div className={styles.filters}>
                        <ResultFilter
                            term={currentTerm}
                            onChange={(filter) => {
                                setActiveFilters(filter);
                                search(currentTerm, false, filter);
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ResultsComponent;
