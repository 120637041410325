import axios from 'axios';
import FormData from 'form-data';
import { SpatialGradientDTO, MaxSARDTO } from '../models/MRIMachineModels';
import SETTING_KEYS from '../constants/setting-keys';
import { processData, ProcessedData } from '../utils/pdfExtractor';
import {
    ArtifactDTO,
    SARDTO,
    SlewDTO,
    TempDTO,
    WaintingPeriodDTO,
} from '../models/ConditionalsModels';
import PROTOCOL_KEYS from '../constants/protocol-keys';

const instance = axios.create({
    baseURL: '',
    timeout: 60000,
});

instance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response.status === 401) {
            deleteCurrentContext();
            window.location.href = '/login';
        }
        return error.response;
    }
);

const deleteCurrentContext = () => {
    const storedContext = JSON.parse(localStorage.getItem('context') as string);

    if (storedContext !== null) {
        localStorage.removeItem('context');
    }
};

const getCurrentContext = () => {
    const storedContext = JSON.parse(localStorage.getItem('context') as string);

    if (storedContext !== null) {
        return storedContext;
    }
};

export const GETREQUESTOPTIONS = () => {
    return {
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${getCurrentContext().token}`,
        },
    };
};

export const getNewContext = async () => {
    return (await instance.get('/api/user/context', GETREQUESTOPTIONS())).data;
};

export const logout = async () => {
    return (await instance.post('/api/user/logout', {}, GETREQUESTOPTIONS()))
        .status;
};

export const getImplantById = async (id: string) => {
    return (await instance.get('/api/implants/find/' + id, GETREQUESTOPTIONS()))
        .data;
};

export const getHistoryVersionsByImplantId = async (id: string) => {
    return (
        await instance.get('/api/implants/history/' + id, GETREQUESTOPTIONS())
    ).data;
};

export const loadImplantSourceFile = async (id: string) => {
    return (
        await instance.get(
            '/api/image/load-source-file/' + id,
            GETREQUESTOPTIONS()
        )
    ).data;
};

export const getAllUsers = async () => {
    return (await instance.get('api/user/', GETREQUESTOPTIONS())).data;
};

export const getGuestUsers = async () => {
    return (await instance.get('api/user/guests', GETREQUESTOPTIONS())).data;
};

export const deleteUser = async (userId: string) => {
    const res = await instance.post(
        'api/user/delete',
        { userId },
        GETREQUESTOPTIONS()
    );
    return res.data;
};
export const deactivateUser = async (userId: string, newExpiryDate: Date) => {
    const res = await instance.post(
        'api/user/set-expiry',
        { userId, newExpiryDate },
        GETREQUESTOPTIONS()
    );
    return res.data;
};
export const getUsersByDepartment = async () => {
    return (await instance.get('api/user/by-department', GETREQUESTOPTIONS()))
        .data;
};

export const getAllNews = async () => {
    return (await instance.get('/api/news/', GETREQUESTOPTIONS())).data;
};

export const allImplantsGetTypes = async () => {
    return (await instance.get('/api/all-implants/types', GETREQUESTOPTIONS()))
        .data;
};

export const allImplantsGetManufacturers = async () => {
    return (
        await instance.get(
            '/api/all-implants/manufacturers',
            GETREQUESTOPTIONS()
        )
    ).data;
};

export const allImplantsGetImplantsByType = async (name: string) => {
    return (
        await instance.get(
            '/api/all-implants/by-type?name=' + name,
            GETREQUESTOPTIONS()
        )
    ).data;
};

export const allImplantsGetImplantsByManufacturer = async (id: string) => {
    return (
        await instance.get(
            '/api/all-implants/by-manufacturer?id=' + id,
            GETREQUESTOPTIONS()
        )
    ).data;
};

export const getImplantPreview = async (id: string) => {
    return (
        await instance.get(
            '/api/suggestions/preview/implant?id=' + id,
            GETREQUESTOPTIONS()
        )
    ).data;
};

export const getManufacturerPreview = async (id: string) => {
    return (
        await instance.get(
            '/api/suggestions/preview/manufacturer?id=' + id,
            GETREQUESTOPTIONS()
        )
    ).data;
};

export const getProtocolPreview = async (id: string) => {
    return (
        await instance.get(
            '/api/suggestions/preview/protocol?id=' + id,
            GETREQUESTOPTIONS()
        )
    ).data;
};

export const getTypePreview = async (name: string) => {
    return (
        await instance.get(
            '/api/suggestions/preview/type?name=' + name,
            GETREQUESTOPTIONS()
        )
    ).data;
};

export const getSearchSuggestions = async (term: string) => {
    return (
        await instance.get(
            '/api/search/suggestions?term=' + term,
            GETREQUESTOPTIONS()
        )
    ).data;
};

export const autocomplete = async (term: string) => {
    return (
        await instance.get(
            '/api/implants/autocomplete?term=' + term,
            GETREQUESTOPTIONS()
        )
    ).data;
};

export const getSearchFilters = async (
    term: string,
    filters: {
        [SETTING_KEYS.type]: string[];
        [SETTING_KEYS.manufacturer]: string[];
        [SETTING_KEYS.bodyPlacement]: string[];
    }
) => {
    const res = await instance.get(
        `/api/search/get-filters?term=${term}&filters=${JSON.stringify(
            filters
        )}`,
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export const mriCodeExists = async (code: string) => {
    return (
        await instance.get(
            '/api/search/validate-code?code=' + code,
            GETREQUESTOPTIONS()
        )
    ).data;
};

export const getImplantsByTerm = async (
    term: string,
    skipAmount: number,
    sortField: string,
    sortMode: string,
    useSemanticSearch: boolean,
    filters?: { [key: string]: string[] }
) => {
    return (
        await instance.get('/api/implants/search/', {
            params: {
                term,
                skipValue: skipAmount,
                sortField: sortField,
                sortMode: sortMode,
                useSemanticSearch: useSemanticSearch,
                filters: JSON.stringify(filters),
            },
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${getCurrentContext().token}`,
            },
            timeout: 60000,
        })
    ).data;
};

export const getAllImplants = async () => {
    return (await instance.get('/api/implants', GETREQUESTOPTIONS())).data;
};

export const getProfileImage = async () => {
    return (
        await instance.get('/api/image/load-profile-image', {
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${getCurrentContext().token}`,
            },
        })
    ).data;
};

export const getAllActors = async () => {
    return (await instance.get('/api/actor/', GETREQUESTOPTIONS())).data;
};

export const getAllDepartments = async () => {
    return (await instance.get('/api/department/', GETREQUESTOPTIONS())).data;
};

export const getActiveDepartments = async () => {
    return (
        await instance.get(
            '/api/department/active-departments',
            GETREQUESTOPTIONS()
        )
    ).data;
};

export const getDepartmentActors = async () => {
    const res = await instance.get(
        '/api/department/actors',
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export const getDepartmentsByContext = async () => {
    return (
        await instance.get('/api/department/by-context', GETREQUESTOPTIONS())
    ).data;
};

export const getAllNewsCategories = async () => {
    return (await instance.get('/api/news/category', GETREQUESTOPTIONS())).data;
};

export const findLogs = async () => {
    return (await instance.get('/api/log', GETREQUESTOPTIONS()))?.data;
};

export const getLogsByUser = async () => {
    return (await instance.get('/api/log/by-user', GETREQUESTOPTIONS()))?.data;
};

export const getSearchQyeryByUser = async () => {
    return (await instance.get('/api/log/search-query', GETREQUESTOPTIONS()))
        ?.data;
};

export const getFilterSearchesByUser = async () => {
    return (await instance.get('/api/log/filters', GETREQUESTOPTIONS()))?.data;
};

export const getAnonymizedSearches = async () => {
    return (await instance.get('/api/log/searches', GETREQUESTOPTIONS()))?.data;
};

export const checkSearchLog = async (id: string) => {
    return (
        await instance.get('/api/log/check-search/' + id, GETREQUESTOPTIONS())
    )?.data;
};

export const getAllGudidImplants = async () => {
    return (await instance.get('/api/implants/gudid', GETREQUESTOPTIONS()))
        .data;
};

// export interface ApiResponse {
//     success: boolean;
//     message: string;
// }

export const addImplant = async (
    info: ImplantInfoDTO,
    conditionals: ConditionalsDTO
) => {
    const res = instance.post(
        '/api/implants/add',
        {
            Manufacturer: info.Manufacturer,
            info,
            conditionals,
        },
        GETREQUESTOPTIONS()
    );
    return res;
};

export interface ImplantInfoDTO {
    Manufacturer: string;
    [SETTING_KEYS.link]: string;
    [SETTING_KEYS.type]: string;
    [SETTING_KEYS.model]: string;
    [SETTING_KEYS.modelNo]: string;
    [SETTING_KEYS.mriStatus]: number;
}

export interface ConditionalsDTO {
    [SETTING_KEYS.addInfo]: any;
    [SETTING_KEYS.waitingPeriod]: WaintingPeriodDTO;
    [SETTING_KEYS.fieldStrength]: number[];
    [SETTING_KEYS.mriArc]: string;
    [SETTING_KEYS.spatialGradient]: SpatialGradientDTO;
    [SETTING_KEYS.slewRate]: SlewDTO;
    [SETTING_KEYS.sar]: SARDTO;
    [SETTING_KEYS.brms]: string;
    [SETTING_KEYS.coilType]: string;
    [SETTING_KEYS.artefacts]: ArtifactDTO;
    [SETTING_KEYS.temp]: TempDTO;
    [SETTING_KEYS.prep]: any;
    [SETTING_KEYS.image]: string;
}

export const updateImplant = async (
    info: ImplantInfoDTO,
    conditionals: ConditionalsDTO,
    isVersionUpdate: boolean
) => {
    const res = instance.post(
        '/api/implants/update',
        {
            isVersionUpdate,
            Manufacturer: info.Manufacturer,
            info,
            conditionals,
        },
        GETREQUESTOPTIONS()
    );
    return res;
};

export const draftImplant = async (
    info: ImplantInfoDTO,
    conditionals: ConditionalsDTO
) => {
    const res = instance.post(
        '/api/implants/draft',
        {
            Manufacturer: info.Manufacturer,
            info,
            conditionals,
        },
        GETREQUESTOPTIONS()
    );
    return res;
};

export const addManufacturer = async (name: string) => {
    const res = await instance.post(
        '/api/manufacturers/add',
        { name },
        GETREQUESTOPTIONS()
    );
    return res;
};

export const getAllManufactures = async () => {
    const res = await instance.get('/api/manufacturers', GETREQUESTOPTIONS());
    return res.data;
};

export const listSourceFiles = async () => {
    const res = await instance.get(
        '/api/image/list-source-files',
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export const uploadSourceFile = async (file: any, implantId?: string) => {
    let data = new FormData();
    data.append('file', file, file.name);
    if (implantId) {
        data.append('implant_id', implantId);
    }

    try {
        const response = await axios.post(
            '/api/image/upload-source-file',
            data,
            {
                headers: {
                    accept: 'application/json',
                    'Accept-Language': 'en-US,en;q=0.8',
                    'Content-Type': `multipart/form-data; boundary=${
                        (data as any)._boundary
                    }`,
                    authorization: `Bearer ${getCurrentContext().token}`,
                },
            }
        );
        return true;
    } catch (error) {
        return false;
    }
};

//Implant image

export const uploadImplantImage = async (image: any, implantId?: string) => {
    let data = new FormData();
    data.append('file', image, image.name);
    data.append('implant_id', implantId);

    await axios.post('/api/image/upload-implant-image', data, {
        headers: {
            accept: 'application/json',
            'Accept-Language': 'en-US,en;q=0.8',
            'Content-Type': `multipart/form-data; boundary=${
                (data as any)._boundary
            }`,
            authorization: `Bearer ${getCurrentContext().token}`,
        },
    });
};

export const extractDataFromPDF = async (
    fileName: string
): Promise<{ error?: string; data?: ProcessedData }> => {
    let response;

    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${getCurrentContext().token}`,
        'Content-Type': 'application/json',
    };

    try {
        response = await axios.post(
            '/api/image/extract-implant-data',
            { fileName },
            { headers }
        );

        if (response.data.error) {
            return { error: response.data.error };
        }

        const processedData = processData(response.data);
        return { data: processedData };
    } catch (error) {
        let message = 'There was a problem processing the file.';
        if (axios.isAxiosError(error) && error.response) {
            message = error.response.data.error;
        }
        return { error: message };
    }
};

interface VideoMetaData {
    title: string;
    tags: string[];
    author: string;
    thumbnail: string;
}

export const uploadVideoFile = async (
    file: any,
    metaData: VideoMetaData,
    publish?: boolean
) => {
    let data = new FormData();
    data.append('file', file, file.name);
    data.append('metaData', JSON.stringify(metaData));
    publish && data.append('publish', publish ? 'true' : 'false');

    const response = await axios.post('/api/image/upload-video-file', data, {
        headers: {
            accept: 'application/json',
            'Accept-Language': 'en-US,en;q=0.8',
            'Content-Type': `multipart/form-data; boundary=${
                (data as any)._boundary
            }`,
            authorization: `Bearer ${getCurrentContext().token}`,
        },
    });
    return response;
};

export const uploadThumbnailFile = async (file: any, video_id: string) => {
    let data = new FormData();
    data.append('file', file, file.name);
    data.append('video_id', video_id);

    const response = await axios.post(
        '/api/image/upload-thumbnail-file',
        data,
        {
            headers: {
                accept: 'application/json',
                'Accept-Language': 'en-US,en;q=0.8',
                'Content-Type': `multipart/form-data; boundary=${
                    (data as any)._boundary
                }`,
                authorization: `Bearer ${getCurrentContext().token}`,
            },
        }
    );
    return response;
};

export const logSearchStart = (term: string) => {
    instance.post('/api/log/start', { search: term }, GETREQUESTOPTIONS());
};

export const uploadProfileImage = (image: any) => {
    let data = new FormData();
    data.append('file', image, image.name);

    instance
        .post('/api/image/upload-profile-image', data, {
            headers: {
                accept: 'application/json',
                'Accept-Language': 'en-US,en;q=0.8',
                'Content-Type': `multipart/form-data; boundary=${
                    (data as any)._boundary
                }`,
                authorization: `Bearer ${getCurrentContext().token}`,
            },
        })
        .then((response) => {
            window.location.reload();
        })
        .catch((error) => {});
};

export const addActor = (name: string, rights: string[]) => {
    const rightsStr = rights.join(',');
    instance.post(
        '/api/actor/add',
        {
            role: name,
            rights: rights,
        },
        GETREQUESTOPTIONS()
    );
};

export const createNewsPost = (data: object) => {
    instance.post('/api/news/add', data, GETREQUESTOPTIONS());
};

export const addNewsCategory = (catInfo: object) => {
    instance.post('/api/news/category/add', catInfo, GETREQUESTOPTIONS());
};

export const addDepartment = async (department: object): Promise<any> => {
    const res = await instance.post(
        '/api/department/add',
        { department },
        GETREQUESTOPTIONS()
    );
    return res;
};

export const deactivateDepartmentsLisense = async (
    departmentId: string
): Promise<boolean> => {
    const res = await instance.post(
        '/api/department/deactivate-department',
        { departmentId },
        GETREQUESTOPTIONS()
    );
    return res.status === 200;
};

export const updateDepartment = async (
    departmentId: string,
    info: { end_date: Date }
): Promise<boolean> => {
    const res = await instance.post(
        '/api/department/update',
        { departmentId, info },
        GETREQUESTOPTIONS()
    );
    return res.status === 200;
};

export const registerUser = async (user: object) => {
    const res = await instance.post(
        '/api/user/register',
        { user },
        GETREQUESTOPTIONS()
    );
    return res.status === 200;
};

export const initiatePwReset = async (email: string) => {
    const res = await instance.post('/api/user/initiate-pw-reset', { email });
    return res.data;
};

export const setPassword = (password: string, id: string) => {
    instance.post('/api/user/set-password', { password, id });
};

export interface UserSignUpInfo {
    name: string;
    email: string;
    departmentId?: string;
    actor?: string;
    actor_id?: string;
    password: string;
    repeatPassword: string;
}

export const userExists = async (email: string) => {
    const res = await instance.get('/api/user/user-exists/' + email);
    return res.data;
};

export const trialSignUp = async (
    user: UserSignUpInfo
): Promise<{ success: boolean; message: string }> => {
    const res = await instance.post('/api/user/trial-signup', {
        user,
    });
    return res?.data;
};

export const confirmEmail = async (id: string): Promise<boolean> => {
    const res = await instance.post('/api/invitation/confirm-email/', {
        id,
    });
    return res.status === 200;
};

export const signupWithInvitation = async (
    user: UserSignUpInfo,
    invitationId?: string
): Promise<{ success: boolean; message: string }> => {
    const res = await instance.post('/api/user/invite-signup', {
        user,
        invitationId,
    });
    return res?.data;
    // return {
    //     success: false,
    //     message: 'test'
    // }
};

export const updateDeptRole = async (userId: string, actor: string) => {
    console.log(userId, actor);
    const res = await instance.post(
        '/api/user/update-dept-role',
        { user_id: userId, actor },
        GETREQUESTOPTIONS()
    );
    return res.status === 200;
};

export const createInvitations = async (
    emails: string[],
    isAdmin: boolean
): Promise<boolean> => {
    const res = await instance.post(
        '/api/invitation/create',
        { emails, isAdmin },
        GETREQUESTOPTIONS()
    );
    return res.status === 200;
};

export const createCustomInvitation = async (info: {
    email: string;
    activationDate: string;
    expiryDate: string;
    actor?: string;
    department_id?: string;
}): Promise<boolean> => {
    if (info.actor === '') {
        info.actor = null;
    }
    const res = await instance.post(
        '/api/invitation/create-custom',
        { info },
        GETREQUESTOPTIONS()
    );
    return res.status === 200;
};

export const sendInvitationReminders = async (
    infos: { email: string; id: string }[],
    title: string,
    news: string
): Promise<boolean> => {
    const res = await instance.post(
        '/api/invitation/send-reminders',
        { infos, title, news },
        GETREQUESTOPTIONS()
    );
    return res.status === 200;
};

export const getAllMissingImplantReports = async () => {
    const res = await instance.get('/api/report/', GETREQUESTOPTIONS());
    return res.data;
};

export const createAdminInvitation = async (
    email: string,
    department_id: string
): Promise<boolean> => {
    const res = await instance.post(
        '/api/invitation/create-admin',
        { email, department_id },
        GETREQUESTOPTIONS()
    );
    return res.status === 200;
};

export const createManufacturerAdminInvitation = async (
    email: string,
    manufacturer_id: string
): Promise<boolean> => {
    const res = await instance.post(
        '/api/invitation/create-manufacturer-admin',
        { email, manufacturer_id },
        GETREQUESTOPTIONS()
    );
    return res.status === 200;
};

export interface Invitation {
    _id: string;
    email: string;
    isAdmin?: string;
    name?: string;
    expiry?: string;
}

export const getInvitations = async (): Promise<Invitation[]> => {
    const res = await instance.get('/api/invitation/', GETREQUESTOPTIONS());
    return res.data;
};

export const getGuestsInvitations = async (): Promise<Invitation[]> => {
    const res = await instance.get(
        '/api/invitation/guests',
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export const migrateGuestTrialToDept = async (
    email: string,
    department_id: string,
    newExpiry: Date,
    isAdmin: boolean
) => {
    const res = await instance.post(
        '/api/user/migrate-guest',
        { email, department_id, newExpiry, isAdmin },
        GETREQUESTOPTIONS()
    );
    return res.status === 200;
};

export const validateAndGetInvitationData = async (id: string) => {
    const res = await instance.get('/api/invitation/validate?id=' + id);
    return res?.data;
};

export const deleteInvitation = async (id: string) => {
    const res = await instance.post(
        '/api/invitation/delete',
        { id },
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export const deleteAllInvitations = async () => {
    const res = await instance.delete(
        '/api/invitation/delete-all',
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export const getHistory = async () => {
    const res = await instance.get('/api/history/', GETREQUESTOPTIONS());
    return res.data;
};

export const getAllVideos = async () => {
    const res = await instance.get('/api/video-overview/', GETREQUESTOPTIONS());
    return res.data;
};

export const getPublishedVideos = async () => {
    const res = await instance.get(
        '/api/video-overview/published',
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export interface MRIMachineDTO {
    Model: string;
    Name: string;
    FieldStrength: number;
    SpatialGradient: SpatialGradientDTO;
    SlewRatePerAxis: number;
    MaxSlewRate: number;
    MaxSAR: MaxSARDTO;
    MaxB1rms: string;
    TRCoils: string;
}
export const getAllMRIMachinesByDepartment = async () => {
    const res = await instance.get('/api/room-overview/', GETREQUESTOPTIONS());
    return res.data;
};

export const addMRIMachine = async (mriMachine: MRIMachineDTO) => {
    const res = await instance.post(
        '/api/room-overview/add',
        { mriMachine },
        GETREQUESTOPTIONS()
    );
    return res;
};

export const updateMRIMachine = async (mriMachine: MRIMachineDTO) => {
    const res = instance.post(
        '/api/room-overview/update',
        { mriMachine },
        GETREQUESTOPTIONS()
    );
    return res;
};

export const getMRIMachineById = async (id: string) => {
    return (await instance.get('/api/room-overview/' + id, GETREQUESTOPTIONS()))
        .data;
};

export const deleteMRIMachine = async (machineId: string) => {
    const res = await instance.post(
        '/api/room-overview/delete',
        { machineId },
        GETREQUESTOPTIONS()
    );
    return res;
};

export const getBookmarks = async () => {
    const res = await instance.get('/api/bookmarks/', GETREQUESTOPTIONS());
    return res.data;
};

export const addBookmark = async (implantId: string) => {
    const res = await instance.post(
        '/api/bookmarks/add',
        { implantId },
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export const deleteBookmark = async (implantId: string) => {
    const res = await instance.post(
        '/api/bookmarks/delete',
        { implantId },
        GETREQUESTOPTIONS()
    );
    return res.data;
};

interface MissingImplant {
    searchTerm: string;
    name: string;
    type: string;
    info: string;
}

export const reportMissingImplant = async (info: MissingImplant) => {
    const res = await instance.post(
        '/api/report/create',
        info,
        GETREQUESTOPTIONS()
    );
    return res;
};

export const getUnreadNotifications = async () => {
    const res = await instance.get(
        '/api/notification/unread',
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export const getCustomConditionalsFromList = async (ids: any) => {
    const params = new URLSearchParams({
        list: ids,
    });
    const res = await instance.get(`/api/custom/from-list`, {
        ...GETREQUESTOPTIONS(),
        params,
    });
    return res.data;
};
export const getCustomConditionals = async (settings: any) => {
    if (settings.manufacturers) {
        settings.manufacturers = settings.manufacturers.map((m: string) =>
            m.replace(',', '\\c')
        );
    }
    if (settings.types) {
        settings.types = settings.types.map((t: string) =>
            t.replace(',', '\\c')
        );
    }
    const params = new URLSearchParams(settings);
    const res = await instance.get(`/api/custom/find`, {
        ...GETREQUESTOPTIONS(),
        params: params,
    });
    return res.data;
};
export const getAllTypes = async () => {
    const res = await instance.get(
        '/api/custom/settings/types',
        GETREQUESTOPTIONS()
    );
    return res.data;
};
export const getTypesForConditonalFinder = async () => {
    const res = await instance.get(
        '/api/custom/settings/findertypes',
        GETREQUESTOPTIONS()
    );
    return res.data;
};
export const getAllFieldstrength = async () => {
    const res = await instance.get(
        '/api/custom/settings/field-strengths',
        GETREQUESTOPTIONS()
    );
    return res.data;
};
export const getAllManufacturesCustomConditional = async () => {
    const res = await instance.get(
        '/api/custom/settings/manufactures',
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export const loadLatestGudidImplants = async () => {
    const res = await instance.post(
        '/api/gudid/enqueue/latest',
        {},
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export const loadAllGudidImplants = async () => {
    const res = await instance.post(
        '/api/gudid/enqueue/all',
        {},
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export const startGudidWorker = async () => {
    const res = await instance.post('/api/gudid/run', {}, GETREQUESTOPTIONS());
    return res.data;
};

export const stopGudidWorker = async () => {
    const res = await instance.post('/api/gudid/stop', {}, GETREQUESTOPTIONS());
    return res.data;
};

export const getRecurringJob = async (name?: string) => {
    if (!name) {
        name = '';
    }
    const res = await instance.get('/api/recurring/' + name);
    return res.data;
};

export const updateSearchIndices = async () => {
    const res = await instance.post(
        `/api/search/update-elastic-index`,
        {},
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export const getPublishedPredefined = async () => {
    const res = await instance.get(`/api/predefined`, GETREQUESTOPTIONS());
    return res.data;
};

export const getAllPredefined = async () => {
    const res = await instance.get(`/api/predefined/all`, GETREQUESTOPTIONS());
    return res.data;
};

export const getPredefinedById = async (id: string) => {
    const res = await instance.get(
        `/api/predefined/by-id/${id}`,
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export const editFilterSetting = async (id: string, filterOptions: any) => {
    const res = await instance.post(
        `/api/predefined/edit`,
        { id, filterOptions },
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export const saveFilterSetting = async (filterOptions: any) => {
    const res = await instance.post(
        `/api/predefined/add`,
        filterOptions,
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export const publishPredefined = async (id: string) => {
    const res = await instance.post(
        `/api/predefined/publish`,
        { id },
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export const deleteFilterSetting = async (filter_id: string) => {
    const res = await instance.post(
        `/api/predefined/delete`,
        { filter_id },
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export interface UserEditedInfo {
    name?: string;
    email?: string;
}

export interface UserSettings {
    fontSize: string;
}

export const updateUserInfo = async (user: UserEditedInfo) => {
    const res = await instance.post(
        '/api/user-profile/edit-info',
        user,
        GETREQUESTOPTIONS()
    );
    return res.status === 200;
};

export interface UserNewPassword {
    oldPassword: string;
    newPassword: string;
}

export const updateUserPassword = async (
    user: UserNewPassword
): Promise<{ success: boolean; message: string }> => {
    const res = await instance.post(
        '/api/user-profile/update-password',
        user,
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export const getImplantComments = async (implantId: string) => {
    const res = await instance.get(
        `/api/comment/${implantId}`,
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export const createComment = async (implantId: string, comment: string) => {
    const res = await instance.post(
        `/api/comment/add`,
        { implantId: implantId, comment: comment },
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export const editComment = async (commentId: string, comment: string) => {
    const res = await instance.put(
        `/api/comment/edit/${commentId}`,
        { comment: comment },
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export const deleteComment = async (commentId: string) => {
    const res = await instance.delete(
        `/api/comment/delete/${commentId}`,
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export const getActorsWithCommentPermission = async () => {
    const res = await instance.get(
        '/api/admin/permissions/comment',
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export const createCommentPermission = async (actors: string[]) => {
    const res = await instance.post(
        '/api/admin/permissions/comment/create',
        { actors: actors },
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export const searchAssistant = async (searchTerm: string) => {
    const res = await instance.post(
        '/api/generate/completion',
        { searchTerm: searchTerm },
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export const getAllReportedImplants = async () => {
    const res = await instance.get(
        '/api/reported-implants',
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export const deleteImplantReport = async (reportId: string) => {
    const res = await instance.delete(
        `/api/reported-implants/delete/${reportId}`,
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export const sendNotificationEmail = async (reportId: string) => {
    const res = await instance.post(
        '/api/reported-implants/missing-implant-notification',
        { reportId: reportId },
        GETREQUESTOPTIONS()
    );
    return res.data;
};
export const updateLicenses = async (deptId: string, licenses: number) => {
    const res = await instance.post(
        '/api/department/set-licenses',
        { deptId: deptId, licenses: licenses },
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export const getDeparimentInfo = async () => {
    return (await instance.get('/api/department/get', GETREQUESTOPTIONS()))
        .data;
};

export const getDepartmentAdmin = async (deptId: string) => {
    return (
        await instance.get(
            `api/department/department-admin/${deptId}`,
            GETREQUESTOPTIONS()
        )
    ).data;
};

export const assignDeptPermission = async (
    userId: string,
    permission: string
) => {
    const res = await instance.post(
        '/api/department/permissions/assign',
        { userId: userId, permission: permission },
        GETREQUESTOPTIONS()
    );
    return res.status === 200;
};

export const removeDeptPermission = async (
    userId: string,
    permission: string
) => {
    const res = await instance.post(
        '/api/department/permissions/remove',
        { userId: userId, permission: permission },
        GETREQUESTOPTIONS()
    );
    return res.status === 200;
};

export const verifyDeptImplant = (implant_id: string) => {
    const res = instance.post(
        '/api/department/implant/verify',
        { implant_id },
        GETREQUESTOPTIONS()
    );
    return res;
};
export const unverifyDeptImplant = (implant_id: string) => {
    const res = instance.post(
        '/api/department/implant/unverify',
        { implant_id },
        GETREQUESTOPTIONS()
    );
    return res;
};
export const getDeptVerifiedImplants = async () => {
    const res = await instance.get(
        '/api/department/implant',
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export const getImplantsFromManufacturer = async () => {
    const res = await instance.get(
        '/api/manufacturers/get-implants/',
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export const getImplantsByManufacturer = async (manufacturerId: string) => {
    const res = await instance.get(
        '/api/implants/by-manufacturer/' + manufacturerId,
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export const verifyImplant = async (implantId: string) => {
    const res = await instance.post(
        '/api/implants/verify/',
        { implantId },
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export const archiveImplant = async (implantId: string) => {
    const res = await instance.post(
        '/api/implants/archive/',
        { implantId },
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export const recentlyUpdatedImplants = async (date: Date) => {
    const res = await instance.get(
        '/api/implants/recent/' + date,
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export const getExpiringImplants = async () => {
    const res = await instance.get(
        '/api/implants/expiring',
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export const extendImplantExpiry = async (implantId: string) => {
    const res = await instance.post(
        '/api/implants/extend-expiry',
        { implantId },
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export const getImplantNames = async () => {
    const res = await instance.get(
        '/api/implants/all-names',
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export const getConditionalsForCopy = async (implantId: string) => {
    const res = await instance.get(
        '/api/implants/copy-conditionals/' + implantId,
        GETREQUESTOPTIONS()
    );
    if (res.status === 400) {
        return "Couldn't retrieve conditionals for chosen implant. Try another one";
    }
    return res.data;
};

export const getGUDIDData = async (gudidDeviceId: number) => {
    const response = await instance.get(
        '/api/implants/gudid-info/' + gudidDeviceId,
        GETREQUESTOPTIONS()
    );
    if (response.status === 400) {
        return "Couldn't retrieve gudid info for chosen devide id.";
    }
    return response.data;
};

export interface QueuedImplant {
    [SETTING_KEYS.filename]: string;
    status: 'queued' | 'assigned' | 'completed';
}

export const addQueuedImplant = async (queuedImplant: QueuedImplant) => {
    const response = await instance.post(
        '/api/queued-implants/add',
        queuedImplant,
        GETREQUESTOPTIONS()
    );
    return response;
};

export const getQueuedImplants = async () => {
    const response = await instance.get(
        '/api/queued-implants/',
        GETREQUESTOPTIONS()
    );
    return response.data;
};

export const assignQueuedImplant = async (queuedImplant: QueuedImplant) => {
    const response = await instance.post(
        '/api/queued-implants/assign',
        queuedImplant,
        GETREQUESTOPTIONS()
    );
    return response.data;
};

export const completeQueuedImplant = async (filename: string) => {
    const response = await instance.post(
        '/api/queued-implants/complete',
        { filename },
        GETREQUESTOPTIONS()
    );
    return response.data;
};

export const removeImplantApproval = async (implantId: string) => {
    const response = await instance.post(
        '/api/approval/remove',
        { implantId },
        GETREQUESTOPTIONS()
    );
    return response.data;
};

export const approveImplant = async (implantId: string, comment: string) => {
    const response = await instance.post(
        '/api/approval/approve',
        { implantId, comment },
        GETREQUESTOPTIONS()
    );
    return response.data;
};

export const getApprovedImplants = async () => {
    const response = await instance.get('/api/approval', GETREQUESTOPTIONS());
    return response.data;
};

export const getApprovalInfo = async (implantId: string) => {
    const response = await instance.get(
        '/api/approval/info/' + implantId,
        GETREQUESTOPTIONS()
    );
    return response.data;
};

export const getImplantsForReapproval = async () => {
    const response = await instance.get(
        '/api/approval/for-reapproval',
        GETREQUESTOPTIONS()
    );
    return response.data;
};

export interface EditLogEntry {
    date: Date;
    userID: string;
    comment: string;
}

export interface InternalProtocolDTO {
    _id?: string;
    [PROTOCOL_KEYS.title]: string;
    [PROTOCOL_KEYS.description]: string;
    [PROTOCOL_KEYS.def]: string;
    [PROTOCOL_KEYS.scope]: string;
    [PROTOCOL_KEYS.procedure]: string;
    [PROTOCOL_KEYS.ref]: string[];
    [PROTOCOL_KEYS.expiry]: Date;
    createdAt?: Date;
    updatedAt?: Date;
    [SETTING_KEYS.updatedBy]: string;
    [SETTING_KEYS.type]: string;
    [SETTING_KEYS.category]: string;
    [SETTING_KEYS.bodyPlacement]: string;
    [SETTING_KEYS.mriStatus]: number;
    [SETTING_KEYS.editStatus]: number;
    [SETTING_KEYS.filename]: string;
    editLogProtocol: EditLogEntry[];
    verifiedBy: string;
    [SETTING_KEYS.notes]: string;
    [SETTING_KEYS.customFields]: any[];
}

export interface ProtocolConditionalDTO {
    protocol: InternalProtocolDTO;
    conditionals: ConditionalsDTO;
}

export const linkProtocolAndImplant = async (
    protocol_id: string,
    implant_id: string
) => {
    const response = await instance.post(
        '/api/internal-protocols/link',
        { protocol_id, implant_id },
        GETREQUESTOPTIONS()
    );
    return response.data;
};

export const unlinkProtocolAndImplant = async (
    protocol_id: string,
    implant_id: string
) => {
    const response = await instance.post(
        '/api/internal-protocols/unlink',
        { protocol_id, implant_id },
        GETREQUESTOPTIONS()
    );
    return response.data;
};

export const getProtocolFromImplantId = async (id: string) => {
    const response = await instance.get(
        '/api/internal-protocols/from-linked-implant/' + id,
        GETREQUESTOPTIONS()
    );
    return response.data;
};

export const getAllInternalProtocols = async () => {
    const response = await instance.get(
        '/api/internal-protocols/',
        GETREQUESTOPTIONS()
    );
    return response.data;
};

export const getInternalManagedProtocols = async () => {
    const response = await instance.get(
        '/api/internal-protocols/managed',
        GETREQUESTOPTIONS()
    );
    return response.data;
};

export const getInternalProtocolsByDepartment = async () => {
    const response = await instance.get(
        '/api/internal-protocols',
        GETREQUESTOPTIONS()
    );
    return response.data;
};

export const getInternalProtocolById = async (id: string) => {
    const response = await instance.get(
        '/api/internal-protocols/' + id,
        GETREQUESTOPTIONS()
    );
    delete response.data.info.conditionals;
    return response.data;
};

export const createNewInternalProtocol = async (
    info: InternalProtocolDTO,
    conditionals: ConditionalsDTO
) => {
    const res = instance.post(
        '/api/internal-protocols/add',
        {
            info,
            conditionals,
        },
        GETREQUESTOPTIONS()
    );
    return res;
};

export const editInternalProtocol = async (
    info: InternalProtocolDTO,
    conditionals: ConditionalsDTO,
    editComment: string
) => {
    const res = instance.put(
        `/api/internal-protocols/edit/${info._id}`,
        { info, conditionals, editComment },
        GETREQUESTOPTIONS()
    );
    return res;
};

export const verifyInternalProtocol = async (protocolId: string) => {
    const res = instance.put(
        `/api/internal-protocols/verify/${protocolId}`,
        {},
        GETREQUESTOPTIONS()
    );
    return res;
};

export const archiveInternalProtocol = async (protocolId: string) => {
    const res = instance.put(
        `/api/internal-protocols/archive/${protocolId}`,
        {},
        GETREQUESTOPTIONS()
    );
    return res;
};

export const createGroup = async (title: string) => {
    const res = await instance.post(
        '/api/groups/create',
        { title },
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export const updateGroup = async (id: string, title: string) => {
    const res = await instance.put(
        '/api/groups/update',
        { id, title },
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export const deleteGroup = async (id: string) => {
    const res = await instance.delete('/api/groups/delete', {
        data: { id },
        ...GETREQUESTOPTIONS(),
    });
    return res.data;
};

export const addImplantToGroup = async (groupId: string, implantId: string) => {
    const res = await instance.post(
        '/api/groups/add-implant',
        { groupId, implantId },
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export const addProtocolToGroup = async (
    groupId: string,
    protocolId: string
) => {
    const res = await instance.post(
        '/api/groups/add-protocol',
        { groupId, protocolId },
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export const removeImplantFromGroup = async (
    groupId: string,
    implantId: string
) => {
    const res = await instance.delete('/api/groups/remove-implant', {
        data: { groupId, implantId },
        ...GETREQUESTOPTIONS(),
    });
    return res.data;
};

export const removeProtocolFromGroup = async (
    groupId: string,
    protocolId: string
) => {
    const res = await instance.delete('/api/groups/remove-protocol', {
        data: { groupId, protocolId },
        ...GETREQUESTOPTIONS(),
    });
    return res.data;
};

export const getGroupById = async (groupId: string) => {
    const res = await instance.get(
        `/api/groups/by-id?groupId=${groupId}`,
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export const getGroupsByDepartment = async () => {
    const res = await instance.get(
        '/api/groups/department',
        GETREQUESTOPTIONS()
    );
    return res.data;
};

export const getGroupsByImplant = async () => {
    const res = await instance.get(`/api/groups/implant`, GETREQUESTOPTIONS());
    return res.data;
};

export const getGroupsByProtocol = async () => {
    const res = await instance.get(`/api/groups/protocol`, GETREQUESTOPTIONS());
    return res.data;
};
export const createSynonym = async (value: string) => {
    const res = instance.post('/api/synonyms', { value }, GETREQUESTOPTIONS());
    return res;
};

export const getSynonymById = async (id: string) => {
    const res = instance.get(`/api/synonyms/${id}`, GETREQUESTOPTIONS());
    return res;
};

export const updateSynonymById = async (id: string, value: string) => {
    const res = instance.put(
        `/api/synonyms/${id}`,
        { value },
        GETREQUESTOPTIONS()
    );
    return res;
};

export const deleteSynonymById = async (id: string) => {
    const res = instance.delete(`/api/synonyms/${id}`, GETREQUESTOPTIONS());
    return res;
};

export const getAllSynonyms = async () => {
    const res = instance.get('/api/synonyms', GETREQUESTOPTIONS());
    return res;
};

export const configElastic = async () => {
    const res = instance.post(
        '/api/search/config-elastic',
        {},
        GETREQUESTOPTIONS()
    );
    return res;
};
