import { useEffect, useState } from 'react';
import Button, { ButtonType } from '../general-components/Button';
import FormInput from '../general-components/FormInput';
import ConfirmationModal from '../general/modal/ConfirmationModal';
import {
    createGroup,
    getGroupById,
    updateGroup,
} from '../../services/apiService';

const CreateGroupButton: React.FC<{
    onGroupCreated?: (id: string, title: string) => void;
    isEdit?: boolean;
    id?: string;
    defaultTitle?: string;
}> = ({ onGroupCreated, isEdit, id, defaultTitle }) => {
    const [showCreateGroup, setShowCreateGroup] = useState(false);
    const [title, setTitle] = useState('');

    const saveGroup = async () => {
        let groupId: string = id;
        if (isEdit) {
            const res = await updateGroup(id, title);
        } else {
            const res = await createGroup(title);
            groupId = res.id;
        }
        setShowCreateGroup(false);
        onGroupCreated(groupId, title);
        setTitle('');
    };

    // useEffect(() => {
    //     const fetchGroup = async () => {
    //         const res = await getGroupById(id);
    //         setTitle(res.title);
    //     };
    //     if (isEdit) {
    //         fetchGroup();
    //     }
    // }, [isEdit, id]);

    return (
        <div>
            {showCreateGroup && (
                <ConfirmationModal
                    title={isEdit ? 'Edit Group' : 'Create Group'}
                    message="Please enter title of the group"
                    onConfirm={saveGroup}
                    onClose={() => setShowCreateGroup(false)}
                    confirmText={isEdit ? 'Update' : 'Create'}
                    cancelText="Cancel"
                >
                    <FormInput
                        defaultValue={defaultTitle}
                        onChange={(e) => setTitle(e.target.value)}
                        label="Group Title"
                    />
                </ConfirmationModal>
            )}
            <Button
                onClick={() => setShowCreateGroup(true)}
                type={ButtonType.primary}
            >
                {!isEdit ? 'Create Group' : 'Edit Group'}
            </Button>
        </div>
    );
};

export default CreateGroupButton;
