import { useContext, useEffect, useState } from 'react';
import Button, { ButtonType } from '../general-components/Button';
import {
    addImplantToGroup,
    addProtocolToGroup,
    getGroupsByDepartment,
    getGroupsByImplant,
    getGroupsByProtocol,
    removeImplantFromGroup,
    removeProtocolFromGroup,
} from '../../services/apiService';
import ModalLayout from '../general/modal/ModalLayout';
import ComponentSelectList from '../general/select/ComponentSelectList';
import CardComponent from '../general/Cards/CardComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faMobile } from '@fortawesome/free-solid-svg-icons';
import TimedAlert from '../general/Alerts/TimedAlert';
import styles from './AddToGroupButton.module.css';
import AuthContext from '../../store/auth-context';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
const AddToGroupButton: React.FC<{
    type: 'implant' | 'protocol';
    id: string;
}> = ({ type, id }) => {
    const ctx = useContext(AuthContext);

    const [showModal, setShowModal] = useState(false);
    const [groups, setGroups] = useState<any[]>([]);
    const [filteredGroups, setFilteredGroups] = useState<any[]>([]);
    const [confimationMessage, setConfimationMessage] = useState('');

    const getGroups = async () => {
        let res;
        if (type === 'implant') {
            res = await getGroupsByImplant();
        } else {
            res = await getGroupsByProtocol();
        }

        setGroups(res);
        setFilteredGroups(
            res.filter(
                (group: any) =>
                    group.implants.includes(id) || group.protocols.includes(id)
            )
        );
    };

    useEffect(() => {
        getGroups();
    }, [showModal]);

    const addToGroup = async (groupId: string) => {
        if (type === 'implant') {
            setConfimationMessage(
                'Implant ADDED to group ' +
                    groups.find((g) => g._id === groupId)?.title
            );
            await addImplantToGroup(groupId, id);
        }
        if (type === 'protocol') {
            setConfimationMessage(
                'Protocol ADDED to group ' +
                    groups.find((g) => g._id === groupId)?.title
            );
            await addProtocolToGroup(groupId, id);
        }
    };
    const removeFromGroup = async (groupId: string) => {
        if (type === 'implant') {
            setConfimationMessage(
                'Implant REMOVED from group ' +
                    groups.find((g) => g._id === groupId)?.title
            );
            await removeImplantFromGroup(groupId, id);
        }
        if (type === 'protocol') {
            setConfimationMessage(
                'Protocol REMOVED from group ' +
                    groups.find((g) => g._id === groupId)?.title
            );
            await removeProtocolFromGroup(groupId, id);
        }
    };

    return (
        <>
            <TimedAlert message={confimationMessage} duration={1000} />
            {showModal && (
                <ModalLayout
                    hasCloseButton={true}
                    size="large"
                    onClose={() => setShowModal(false)}
                >
                    <h4 className={styles.selectTitle}>Choose Groups</h4>
                    <div className={styles.modal}>
                        <ComponentSelectList
                            items={groups.map((group) => ({
                                comp: (
                                    <CardComponent
                                        key={group.title}
                                        preview={
                                            <FontAwesomeIcon icon={faFolder} />
                                        }
                                        title={group.title}
                                        description={new Date(
                                            group.createdAt
                                        ).toLocaleDateString()}
                                    />
                                ),
                                id: group._id,
                                selected:
                                    group.implants.includes(id) ||
                                    group.protocols.includes(id),
                            }))}
                            onChange={(id, selected) => {
                                if (selected) {
                                    addToGroup(id);
                                } else {
                                    removeFromGroup(id);
                                }
                            }}
                        />
                        <Button
                            onClick={() => setShowModal(false)}
                            type={ButtonType.primary}
                        >
                            Close
                        </Button>
                    </div>
                </ModalLayout>
            )}
            {ctx.hasPermission('Manage Groups') &&
                filteredGroups.length === 0 && (
                    <Button
                        onClick={() => setShowModal(true)}
                        type={ButtonType.dept}
                    >
                        <FontAwesomeIcon icon={faFolder} /> Add to Group
                    </Button>
                )}
            {filteredGroups.length > 0 && (
                <div className={styles.link}>
                    <h4>
                        Groups{' '}
                        {ctx.hasPermission('Manage Groups') && (
                            <span onClick={() => setShowModal(true)}>
                                <FontAwesomeIcon icon={faPenToSquare} />
                            </span>
                        )}
                    </h4>
                    {filteredGroups.map((group) => (
                        <p key={group._id}>
                            <FontAwesomeIcon icon={faFolder} />
                            <a href={'/group/' + group._id}>{group.title}</a>
                        </p>
                    ))}
                </div>
            )}
        </>
    );
};

export default AddToGroupButton;
