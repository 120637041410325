import React, { useContext, useEffect } from 'react';
import {
    BrowserRouter as Router,
    Navigate,
    Route,
    Routes,
} from 'react-router-dom';
import Home from './components/Home';
import Login from './components/login/Login';
import Register from './components/manage-user/Register';
import AuthContext, { StoredContext } from './store/auth-context';
import Navigation from './components/general-components/Navigation';
import ManageUsers from './components/manage-user/ManageUsers';
import Organizations from './components/organizations/Organizations';
import AddImplantPage from './components/add-implant/AddImplantPage';
import News from './components/News';
import AdminPage from './components/admin-page/AdminPage';
import RIGHTS_KEYS from './constants/rights-keys.js';
import styles from './App.module.css';
import Styles from './components/Styles';
import ResetPassword from './components/password-reset/ResetPassword';
import SignupPage from './components/sign-up/SignupPage';
import InitiatePasswordReset from './components/password-reset/InitiatePasswordReset';
import SearchHistory from './components/search/History';
import ResultsPage from './components/search/ResultsPage';
import Bookmarks from './components/bookmarks/Bookmarks';
import UserProfilePage from './components/user-page/UserProfilePage';
import SavedFilter from './components/custom-conditionals/SavedFilter';
import ImplantPage from './components/implant/ImplantPage';
import RoomOverview from './components/room-overview/RoomOverview';
import { DepartmentContextProvider } from './store/department-context';
import ImplantOverviewPage from './components/implant-overview/ImplantOverviewPage';
import VideoUploadPage from './components/video-upload-page/VideoUploadPage';
import VideoOverview from './components/video-overview/VideoOverview';
import ScrapingOverview from './components/scraping-overview/ScrapingOverview';
import RecentImplantsPage from './components/scraping-overview/RecentImplantsPage';
import ImplantExpiryPage from './components/implant-verification/ImplantVerificationPage';
import SourceFilePage from './components/scraping-overview/SourceFilePage';
import { ImplantProvider } from './store/implant-context';
import Disclaimer from './components/ui-components/Disclaimer';
import ConfirmEmailPage from './components/sign-up/ConfirmEmailPage';
import InternalProtocolList from './components/internal-protocols/InternalProtocolList';
import AddInternalProtocol from './components/internal-protocols/AddInternalProtocol';
import ProtocolList from './components/protocols/ProtocolList';
import ProtocolPageWrapper from './components/protocols/ProtocolPageWrapper';
import { FilterContextProvider } from './store/filter-context';
import PredefinedView from './components/custom-conditionals/view/PredefinedView';
import NewPredefinedConditionals from './components/custom-conditionals/NewPredefinedConditionals';
import EditPredefinedConditionals from './components/custom-conditionals/EditPredefinedConditionals';
import AllImplantsPage from './components/implant-list/AllImplantsPage';
import ApprovalPage from './components/approval/ApprovalPage';
import GroupPage from './components/group/GroupPage';

const App = () => {
    const context = useContext(AuthContext);
    let ctx: StoredContext = context.getContext();

    useEffect(() => {
        if (ctx.isLoggedIn) {
            if (ctx.fontSize) {
                document.documentElement.style.fontSize = ctx.fontSize + 'px';
            }
        }
    }, []);

    if (!ctx.isLoggedIn) {
        return (
            <React.Fragment>
                <div className={styles.app}>
                    <Router>
                        <Routes>
                            <Route
                                path="/reset/:id"
                                element={<ResetPassword />}
                            />
                            <Route
                                path="/initiate-reset"
                                element={<InitiatePasswordReset />}
                            />
                            <Route
                                path="/sign-up/:code"
                                element={<SignupPage />}
                            />
                            <Route path="/sign-up/" element={<SignupPage />} />
                            <Route
                                path="/confirm-email"
                                element={<ConfirmEmailPage />}
                            />
                            <Route path="/styles" element={<Styles />} />
                            <Route path="/login" element={<Login />} />
                            <Route path="/" element={<Login />} />
                            <Route
                                path="*"
                                element={<Navigate to="/" replace={true} />}
                            />
                            {/* TODO MATHIAS: Add catch all 404 page */}
                        </Routes>
                    </Router>
                </div>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            {(ctx.actor.role === 'LiteUser' || ctx.expiryDate < new Date()) && (
                <Disclaimer>
                    Your account has expired and therefore only has access to
                    limited features.{' '}
                    <a href="mailto:info@nordinsight.com">Contact us</a> to
                    upgrade your account.
                </Disclaimer>
            )}
            <div className={styles.app} data-theme={context.theme}>
                <Router>
                    <Navigation />
                    <div className={styles.page}>
                        <Routes>
                            <Route path="/styles" element={<Styles />} />
                            {ctx.isLoggedIn &&
                                context.orRights([
                                    RIGHTS_KEYS.manageAllUsers,
                                ]) && (
                                    <Route
                                        path="/organizations"
                                        element={<Organizations />}
                                    />
                                )}
                            {ctx.isLoggedIn &&
                                context.orRights([RIGHTS_KEYS.deptTools]) && (
                                    <Route
                                        path="/protocols"
                                        element={<ProtocolList />}
                                    />
                                )}
                            {ctx.isLoggedIn &&
                                context.orRights([RIGHTS_KEYS.deptTools]) &&
                                ctx.deptPermissions?.includes(
                                    'Manage Protocols'
                                ) && (
                                    <Route
                                        path="/protocols/new"
                                        element={<AddInternalProtocol />}
                                    />
                                )}
                            {ctx.isLoggedIn &&
                                context.orRights([RIGHTS_KEYS.deptTools]) &&
                                ctx.deptPermissions?.includes(
                                    'Manage Protocols'
                                ) && (
                                    <Route
                                        path="/protocols/manage"
                                        element={<InternalProtocolList />}
                                    />
                                )}
                            {ctx.isLoggedIn &&
                                context.orRights([RIGHTS_KEYS.deptTools]) && (
                                    <Route
                                        path="/protocols/:id"
                                        element={<ProtocolPageWrapper />}
                                    />
                                )}

                            {ctx.isLoggedIn &&
                                context.orRights([
                                    RIGHTS_KEYS.manageEmployees,
                                    RIGHTS_KEYS.manageAllUsers,
                                ]) && (
                                    <Route
                                        path="/users"
                                        element={
                                            <DepartmentContextProvider>
                                                <ManageUsers />
                                            </DepartmentContextProvider>
                                        }
                                    />
                                )}
                            {ctx.isLoggedIn &&
                                context.orRights([
                                    RIGHTS_KEYS.detailedInfo,
                                ]) && (
                                    <Route
                                        path="/video-overview"
                                        element={<VideoOverview />}
                                    />
                                )}
                            {ctx.isLoggedIn &&
                                context.orRights([
                                    RIGHTS_KEYS.manageAllUsers,
                                    RIGHTS_KEYS.manageEmployees,
                                ]) && (
                                    <Route
                                        path="/register"
                                        element={<Register />}
                                    />
                                )}
                            {ctx.isLoggedIn &&
                                context.orRights([
                                    RIGHTS_KEYS.manageActors,
                                    RIGHTS_KEYS.addImplant,
                                    RIGHTS_KEYS.createDeptNews,
                                    RIGHTS_KEYS.createNews,
                                ]) && (
                                    <Route
                                        path="/admin/*"
                                        element={<AdminPage />}
                                    />
                                )}
                            {ctx.isLoggedIn &&
                                context.orRights([RIGHTS_KEYS.dbSearch]) && (
                                    <Route
                                        path="/search/:term"
                                        element={
                                            <ImplantProvider>
                                                <FilterContextProvider>
                                                    <ResultsPage />
                                                </FilterContextProvider>
                                            </ImplantProvider>
                                        }
                                    />
                                )}
                            {ctx.isLoggedIn && ctx.department && (
                                <Route
                                    path="/room-overview"
                                    element={<RoomOverview />}
                                />
                            )}
                            {ctx.isLoggedIn && ctx.department && (
                                <Route
                                    path="/group/:id"
                                    element={<GroupPage />}
                                />
                            )}
                            {/* {ctx.isLoggedIn &&
                                ctx.department &&
                                context.orRights([RIGHTS_KEYS.deptTools]) && (
                                    <Route
                                        path="/dept-verified"
                                        element={<DeptVerifiedImplants />}
                                    />
                                    
                                )} */}
                            {ctx.isLoggedIn &&
                                ctx.department &&
                                context.orRights([RIGHTS_KEYS.deptTools]) && (
                                    <Route
                                        path="/approved"
                                        element={<ApprovalPage />}
                                    />
                                )}
                            {ctx.isLoggedIn &&
                                context.orRights([
                                    RIGHTS_KEYS.dbSearch,
                                    RIGHTS_KEYS.manageImplant,
                                ]) && (
                                    <Route
                                        path="/implant/:id"
                                        element={<ImplantPage />}
                                    />
                                )}
                            {ctx.isLoggedIn &&
                                context.orRights([
                                    RIGHTS_KEYS.manageImplant,
                                    RIGHTS_KEYS.manageAllImplants,
                                ]) && (
                                    <Route
                                        path="/implants/"
                                        element={
                                            <ImplantProvider>
                                                <ImplantOverviewPage />
                                            </ImplantProvider>
                                        }
                                    />
                                )}
                            {ctx.isLoggedIn &&
                                context.orRights([
                                    RIGHTS_KEYS.manageImplant,
                                    RIGHTS_KEYS.manageAllImplants,
                                ]) && (
                                    <Route
                                        path="/add-implant"
                                        element={
                                            <ImplantProvider>
                                                <AddImplantPage />
                                            </ImplantProvider>
                                        }
                                    />
                                )}
                            {ctx.isLoggedIn &&
                                context.orRights([
                                    RIGHTS_KEYS.manageImplant,
                                    RIGHTS_KEYS.manageAllImplants,
                                ]) && (
                                    <Route
                                        path="/searches/"
                                        element={<ScrapingOverview />}
                                    />
                                )}
                            {ctx.isLoggedIn &&
                                context.orRights([
                                    RIGHTS_KEYS.manageAllImplants,
                                ]) && (
                                    <Route
                                        path="/list-source-files/"
                                        element={<SourceFilePage />}
                                    />
                                )}
                            {ctx.isLoggedIn &&
                                context.orRights([
                                    RIGHTS_KEYS.verifyImplants,
                                ]) && (
                                    <Route
                                        path="/recent-implants/"
                                        element={
                                            <ImplantProvider>
                                                <RecentImplantsPage />
                                            </ImplantProvider>
                                        }
                                    />
                                )}

                            {ctx.isLoggedIn &&
                                context.orRights([
                                    RIGHTS_KEYS.reverifyImplants,
                                ]) && (
                                    <Route
                                        path="/reverification"
                                        element={
                                            <ImplantProvider>
                                                <ImplantExpiryPage />
                                            </ImplantProvider>
                                        }
                                    />
                                )}
                            {ctx.isLoggedIn && (
                                <Route path="/news/" element={<News />} />
                            )}
                            {ctx.isLoggedIn &&
                                context.orRights([RIGHTS_KEYS.dbSearch]) && (
                                    <Route path="/search" element={<Home />} />
                                )}
                            {ctx.isLoggedIn &&
                                context.orRights([RIGHTS_KEYS.dbSearch]) && (
                                    <Route
                                        path="/history"
                                        element={<SearchHistory />}
                                    />
                                )}
                            {ctx.isLoggedIn &&
                                context.orRights([
                                    RIGHTS_KEYS.advancedSearch,
                                ]) && (
                                    <Route
                                        path="/bookmarks"
                                        element={<Bookmarks />}
                                    />
                                )}
                            {ctx.isLoggedIn &&
                                context.orRights([
                                    RIGHTS_KEYS.advancedSearch,
                                ]) && (
                                    <Route
                                        path="/all"
                                        element={<AllImplantsPage />}
                                    />
                                )}
                            {ctx.isLoggedIn &&
                                context.orRights([
                                    RIGHTS_KEYS.manageVideoUpload,
                                ]) && (
                                    <Route
                                        path="/video-upload"
                                        element={<VideoUploadPage />}
                                    />
                                )}
                            {ctx.isLoggedIn && (
                                <Route
                                    path="/user-profile"
                                    element={<UserProfilePage />}
                                />
                            )}
                            {ctx.isLoggedIn &&
                                context.orRights([RIGHTS_KEYS.dbSearch]) && (
                                    <Route path="/" element={<Home />} />
                                )}
                            {ctx.isLoggedIn && (
                                <Route
                                    path="*"
                                    element={<Navigate to="/" replace={true} />}
                                />
                            )}
                            {ctx.isLoggedIn &&
                                context.orRights([
                                    RIGHTS_KEYS.managePredefined,
                                ]) && (
                                    <Route
                                        path="/predefined/new"
                                        element={
                                            <FilterContextProvider>
                                                <NewPredefinedConditionals />
                                            </FilterContextProvider>
                                        }
                                    />
                                )}
                            {ctx.isLoggedIn &&
                                context.orRights([
                                    RIGHTS_KEYS.managePredefined,
                                ]) && (
                                    <Route
                                        path="/predefined/edit/:id"
                                        element={
                                            <FilterContextProvider>
                                                <EditPredefinedConditionals />
                                            </FilterContextProvider>
                                        }
                                    />
                                )}
                            {ctx.isLoggedIn &&
                                context.orRights([
                                    RIGHTS_KEYS.managePredefined,
                                ]) && (
                                    <Route
                                        path="/predefined/manage"
                                        element={<SavedFilter />}
                                    />
                                )}
                            {ctx.isLoggedIn &&
                                context.orRights([
                                    RIGHTS_KEYS.advancedSearch,
                                ]) && (
                                    <Route
                                        path="/predefined/:id"
                                        element={
                                            <FilterContextProvider>
                                                <PredefinedView />
                                            </FilterContextProvider>
                                        }
                                    />
                                )}
                        </Routes>
                    </div>
                </Router>
            </div>
        </React.Fragment>
    );
};

export default App;
