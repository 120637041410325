import { useEffect, useState } from 'react';
import styles from './ScrapingOverview.module.css';
import {
    checkSearchLog,
    getAnonymizedSearches,
} from '../../services/apiService';
import Button, { ButtonType } from '../general-components/Button';
import Modal from '../manage-user/Modal';
import ResultsComponent from '../search/ResultsComponent';

const ScrapingOverview: React.FC = () => {
    const [searches, setSearches] = useState<any[]>([]);

    const [openTerm, setOpenTerm] = useState<string>('');

    const [isDeletingSearch, setIsDeletingSearch] = useState<boolean>(false);
    const [deletingId, setDeletingId] = useState<string>('');

    const onDelete = async (e: MouseEvent) => {
        e.preventDefault();
        await checkSearchLog(deletingId);
        setSearches(searches.filter((s) => s._id !== deletingId));
    };

    useEffect(() => {
        const getData = async () => {
            const s = await getAnonymizedSearches();
            setSearches(s);
        };

        getData();
    }, []);

    return (
        <div className={styles.wrapper}>
            <h1>Recent Searches</h1>
            <div className={styles.overview}>
                <div className={styles.table}>
                    {searches.map((search) => (
                        <>
                            <p className={styles.first}>{search.searchTerm}</p>
                            <p className={styles.second}>
                                {search.user[0]?.email}
                            </p>
                            <p className={styles.third}>
                                {new Date(
                                    search.createdAt
                                ).toLocaleDateString()}
                            </p>
                            <div className={styles.fourth}>
                                <Button
                                    small={true}
                                    onClick={() => {
                                        window.open(
                                            'https://google.com/search?q=' +
                                                search.searchTerm +
                                                ' MRI safety',
                                            '_blank'
                                        );
                                    }}
                                    type={ButtonType.submit}
                                >
                                    Open
                                </Button>
                                <Button
                                    small={true}
                                    type={ButtonType.primary}
                                    onClick={() => {
                                        setOpenTerm(search.searchTerm);
                                    }}
                                >
                                    See results
                                </Button>
                                {/* <Button
                                    small={true}
                                    type={ButtonType.cancel}
                                    onClick={() => {
                                        setDeletingId(search._id);
                                        setIsDeletingSearch(true);
                                    }}
                                >
                                    Delete
                                </Button> */}
                            </div>
                        </>
                    ))}
                </div>
                {openTerm !== '' && (
                    <div>
                        <ResultsComponent
                            term={openTerm}
                            defaultSemantic={false}
                            isFullview={false}
                        />
                    </div>
                )}
                {isDeletingSearch && (
                    <Modal
                        info={{
                            title: 'Delete',
                            message:
                                'Are you sure you want to delete this entry?',
                        }}
                    >
                        <Button
                            onClick={(e: MouseEvent) => {
                                onDelete(e);
                                setIsDeletingSearch(false);
                            }}
                            type={ButtonType.cancel}
                            small={true}
                        >
                            Delete
                        </Button>
                        <Button
                            onClick={() => setIsDeletingSearch(false)}
                            type={ButtonType.tietary}
                            small={true}
                        >
                            Cancel
                        </Button>
                    </Modal>
                )}
            </div>
        </div>
    );
};

export default ScrapingOverview;
