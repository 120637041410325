import { useContext, useEffect, useState } from 'react';
import AuthContext from '../../store/auth-context';
import {
    approveImplant,
    getApprovedImplants,
    getImplantsForReapproval,
} from '../../services/apiService';
import ConditionalsTable from '../implant/conditionals/ConditionalsTable';
import Button, { ButtonType } from '../general-components/Button';
import SETTING_KEYS from '../../constants/setting-keys';
import styles from './ApprovalPage.module.css';
import Modal from '../manage-user/Modal';
import Implant from '../implant/Implant';

const ApprovalPage = () => {
    const ctx = useContext(AuthContext);

    const [forReapproval, setForReapproval] = useState<any[]>([]);
    const [approved, setApproved] = useState([]);
    const [implantInPreview, setImplantInPreview] = useState('');
    const [commentInPreview, setCommentInPreview] = useState('');

    const getData = async () => {
        if (ctx.hasPermission('Verify')) {
            const imps = await getImplantsForReapproval();
            setForReapproval(imps);
        }
        const imps = await getApprovedImplants();
        console.log(imps);
        setApproved(imps);
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className={styles.wrapper}>
            {implantInPreview !== '' && (
                <>
                    <Modal
                        scrollable={true}
                        info={{
                            title: '',
                            message: '',
                        }}
                        updateView={() => {
                            setImplantInPreview('');
                        }}
                    >
                        <div className={styles.approvalBox}>
                            <h4>Reapprove Implant</h4>
                            <p>(Optional) update comment</p>
                            <textarea
                                defaultValue={commentInPreview}
                                onChange={(e) =>
                                    setCommentInPreview(e.target.value)
                                }
                            />
                            <Button
                                type={ButtonType.submit}
                                onClick={async () => {
                                    await approveImplant(
                                        implantInPreview,
                                        commentInPreview
                                    );
                                    setImplantInPreview('');
                                    setCommentInPreview('');
                                    getData();
                                }}
                            >
                                Approve
                            </Button>
                        </div>
                        <div className={styles.preview}>
                            <Implant isPreview={true} id={implantInPreview} />
                        </div>
                    </Modal>
                </>
            )}
            {ctx.hasPermission('Verify') && (
                <>
                    {forReapproval.length > 0 && (
                        <div>
                            <h2>Implants awaiting approval</h2>
                            <ConditionalsTable
                                hasSeeMore={false}
                                headers={[
                                    'Implant',
                                    'Approved by',
                                    'Approved date',
                                    'Updated date',
                                    '',
                                ]}
                                fields={forReapproval.map((a) => [
                                    <a href={'/implant/' + a.implant_id}>
                                        {a.model}
                                    </a>,
                                    a.approvedBy,
                                    a.approvedAt
                                        ? new Date(
                                              a.approvedAt
                                          ).toLocaleDateString()
                                        : '',
                                    a.updatedAt
                                        ? new Date(
                                              a.updatedAt
                                          ).toLocaleDateString()
                                        : '',
                                    <Button
                                        className={styles.reapprove}
                                        small={true}
                                        onClick={() => {
                                            setImplantInPreview(a.implant_id);
                                            setCommentInPreview(a.comment);
                                        }}
                                        type={ButtonType.dept}
                                    >
                                        Reapprove
                                    </Button>,
                                ])}
                            />
                        </div>
                    )}
                </>
            )}
            {approved.length > 0 && (
                <div>
                    <h2>All Approved Implants</h2>
                    <ConditionalsTable
                        hasSeeMore={false}
                        headers={['Model', 'Type', 'Manufacturer']}
                        fields={approved.map((imp) => [
                            <a href={'/implant/' + imp.info._id}>
                                {imp.info[SETTING_KEYS.model]}
                            </a>,
                            imp.info[SETTING_KEYS.type],
                            imp[SETTING_KEYS.manufacturer],
                        ])}
                    />
                </div>
            )}
            {approved.length === 0 && (
                <div style={{ textAlign: 'center', color: 'grey' }}>
                    <h2>
                        No Approved Implants at{' '}
                        {ctx.getContext().department.name}
                    </h2>
                </div>
            )}
        </div>
    );
};

export default ApprovalPage;
