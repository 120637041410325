import React, { useEffect, useState } from 'react';
import Button, { ButtonType } from '../general-components/Button';
import styles from './BookmarkButton.module.css';
import {
    getBookmarks,
    addBookmark,
    deleteBookmark,
} from '../../services/apiService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark as solidBookmark } from '@fortawesome/free-solid-svg-icons';
import { faBookmark as regularBookmark } from '@fortawesome/free-regular-svg-icons';

interface FavoriteImplant {
    id: string;
}

const BookmarkButton: React.FC<FavoriteImplant> = ({ id }) => {
    const [isFavourite, setIsFavourite] = useState(false);
    const [result, setResult] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleChangeFavourite = async () => {
        setIsLoading(true);

        if (isFavourite) {
            const response = await deleteBookmark(id);
            if (response) {
                setIsFavourite(false);
            }
        } else {
            const response = await addBookmark(id);

            if (response) {
                setIsFavourite(true);
            }
        }
        setIsLoading(false);
    };

    const getFavoriteData = async () => {
        const res = await getBookmarks();
        setResult(res);
    };

    useEffect(() => {
        getFavoriteData();
    }, []);

    useEffect(() => {
        const filteredResult = result.filter(
            (implant: any) => implant.info._id === id
        );
        if (filteredResult.length > 0) {
            setIsFavourite(true);
        } else {
            setIsFavourite(false);
        }
    }, [id, result]);

    return (
        <Button
            onClick={handleChangeFavourite}
            type={ButtonType.action}
            className={styles['bookmarkButton']}
            loading={isLoading}
        >
            {isFavourite ? (
                <>
                    <FontAwesomeIcon
                        icon={solidBookmark}
                        color="var(--main-white)"
                    />
                    Remove from Bookmarks
                </>
            ) : (
                <>
                    <FontAwesomeIcon
                        icon={regularBookmark}
                        color="var(--main-white)"
                    />
                    Save to Bookmarks
                </>
            )}
        </Button>
    );
};

export default BookmarkButton;
