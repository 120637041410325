import { useContext, useEffect, useState } from 'react';
import styles from './GroupsOverview.module.css';
import CardComponent from '../general/Cards/CardComponent';
import { faFolder } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getGroupsByDepartment } from '../../services/apiService';
import AuthContext from '../../store/auth-context';
import CreateGroupButton from './CreateGroupButton';

const GroupsOverview = () => {
    const ctx = useContext(AuthContext);

    const [groups, setGroups] = useState<any[]>([]);

    const getGroups = async () => {
        const res = await getGroupsByDepartment();
        setGroups(res);
    };

    const handleGroupCreated = (id: string, title: string) => {
        const grouObj = {
            _id: id,
            title,
            createdAt: new Date().toISOString(),
            department_id: { name: ctx.getContext().department.name },
        };
        if (groups.length > 0) {
            setGroups([...groups, grouObj]);
        } else {
            setGroups([grouObj]);
        }
    };

    useEffect(() => {
        getGroups();
    }, []);

    return (
        <>
            {(groups?.length > 0 || ctx.hasPermission('Manage Groups')) && (
                <div className={styles.wrapper}>
                    <div className={styles.header}>
                        <h3>Groups</h3>
                        {ctx.hasPermission('Manage Groups') && (
                            <CreateGroupButton
                                onGroupCreated={handleGroupCreated}
                            />
                        )}
                    </div>
                    <div className={styles.groups}>
                        {groups.map((group) => (
                            <CardComponent
                                large
                                key={group.title}
                                preview={<FontAwesomeIcon icon={faFolder} />}
                                title={group.title}
                                description={new Date(
                                    group.createdAt
                                ).toLocaleDateString()}
                                extra={
                                    <>
                                        <div
                                            style={{
                                                width: '10px',
                                                height: '10px',
                                                background: 'green',
                                                content: '',
                                                borderRadius: '50%',
                                            }}
                                        ></div>
                                        {group?.department_id.name}
                                    </>
                                }
                                link={`/group/${group._id}`}
                            />
                        ))}
                        {groups.length === 0 && (
                            <div className={styles.noGroups}>
                                <p>No groups in your department yet</p>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default GroupsOverview;
